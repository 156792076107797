import React from 'react';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { MenuItemBox } from './styled';
import { useNavigate } from 'react-router-dom';

interface MenuItemProps {
  title: string;
  icon: string;
  path: string;
  selectedItem: string;
  changeSelected: (path: string) => void;
}

const MenuItem = ({ title, icon, path }: MenuItemProps) => {
  const navigate = useNavigate();
  const { ref, focused } = useFocusable({
    onEnterPress: () => {
      navigate(path);
    },
  });

  return (
    <MenuItemBox ref={ref} focused={focused}>
      <img src={`/images/icons/${icon}.svg`} alt='' />
      <div>{title}</div>
    </MenuItemBox>
  );
};

export default MenuItem;
