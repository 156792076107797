import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  min-width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

export const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;
