import styled from 'styled-components';

interface MenuItemBoxProps {
  focused: boolean;
}

export const MenuItemBox = styled.div<MenuItemBoxProps>`
  width: 171px;
  color: ${({ focused }) => (focused ? '#fff' : '#9e9e9e')};
  font-weight: ${({ focused }) => (focused ? 700 : 400)};
  font-size: 24px;
  margin-bottom: 37px;
  display: flex;
  align-items: center;
  line-height: 1;

  img {
    margin-right: 12px;
    width: 30px;
  }
  div {
    margin-top: 3px;
  }
`;
