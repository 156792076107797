import styled from 'styled-components';

export interface AssetWrapperProps {
  width?: number;
}

export const AssetWrapper = styled.div<AssetWrapperProps>`
  margin-right: 22px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ?? 132.5}px;
  margin-bottom: 16px;
  position: relative;
`;

export interface AssetBoxProps {
  focused: boolean;
  width?: number;
  height?: number;
}

export const AssetImage = styled.img<AssetBoxProps>`
  background: linear-gradient(225deg, #25223380, #0a0911);
  width: ${({ width }) => width ?? 132.5}px;
  height: ${({ height }) => height ?? 200}px;
  object-fit: cover;
  border-color: white;
  border-style: solid;
  border-width: ${({ focused }) => (focused ? '4px' : 0)};
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 0px 0px 15px -5px #00000080;
`;

export const AssetTitle = styled.div`
  color: white;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
`;

interface SelectedAssetTitleProps {
  height?: number;
}

export const SelectedAssetTitle = styled.div<SelectedAssetTitleProps>`
  color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: calc(${({ height }) => height ?? 200}px - 24px);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);

  img {
    width: 16px;
    margin-right: 4px;
  }
`;
