import { Cheerio, CheerioAPI, load, Element } from 'cheerio';
import { HISTORY } from 'constants/settings';

export const PROXY_URL = 'https://square-night-b5a8.sashafedun46.workers.dev/?';
export const URL = 'https://uakino.club';

export interface Movie {
  name: string;
  link: string;
  image: string;
  genre?: string;
  year?: string;
  actors?: string;
  imdb?: string;
}

export interface MovieInfo {
  title: string;
  subTitle: string;
  image: string | undefined;
  quality: string;
  description: string;
  infoSections: {
    title: string;
    value: string;
  }[];
  id: string | undefined;
  url: string | undefined;
  seasons: {
    name: string;
    link: string | undefined;
  }[];
}

export interface SourcesData {
  sources: {
    id: string | undefined;
    name: string;
  }[];
  videos: {
    name: string;
    id: string | undefined;
    link: string | undefined;
    voice: string | undefined;
  }[];
}

export interface VideoConfig {
  url?: string;
  poster?: string;
  subtitle?: string;
}

export interface Section {
  name: string;
  movies: Movie[];
}

export enum MovieType {
  Movies = 'filmy',
  Series = 'seriesss',
  Cartoons = 'cartoon',
}
const get = (url?: string, isFullLink = false, isJSON = false) =>
  fetch(PROXY_URL + (isFullLink ? '' : URL) + url, { mode: 'cors' }).then(res =>
    isJSON ? res.json() : res.text(),
  );

const findMovieItems = ($parent: Cheerio<Element>, $: CheerioAPI) =>
  $parent
    .find('.movie-item')
    .map((_, movie) => {
      return {
        name: $(movie).find('.movie-title').text(),
        link: $(movie).find('.movie-title').attr('href') || '',
        image: URL + $(movie).find('img').attr('src'),
        genre: $(movie).find('.movie-genre1').text().trim(),
        year: $(movie).find('.movie-desc div:nth-child(5)').text().trim(),
        actors: $(movie).find('.deck-cast1').text().trim(),
        imdb: $(movie).find('.movie-desc div:nth-child(9)').text().trim(),
      };
    })
    .toArray();

export const getMainPageData = async () => {
  const data = await get('/');
  const $ = load(data as any);

  const sections = $('.main-section-inner')
    .map((_, section) => {
      const $section = $(section);
      const sectionName = $section
        .find('.sidebar-title')
        .text()
        .replace(/\n/g, ' ')
        .trim();

      return {
        name: sectionName,
        movies: findMovieItems($section, $),
      };
    })
    .toArray();

  return sections;
};

export const getMovies = async (type: MovieType, page: number) => {
  const data = await get(`/${type}/${page !== 1 ? `page/${page}/` : ''}`);

  const $ = load(data as any);
  const $moviesSection = $('#dle-content');
  const movies = findMovieItems($moviesSection, $);

  return movies;
};

export const getMovie = async (link: string) => {
  const data = await get(link, true);

  const $ = load(data as any);
  const $moviePage = $('#dle-content');

  const infoSections = $moviePage
    .find('.film-info .clearfix')
    .map((_, element) => {
      const $element = $(element);
      return {
        title: $element.find('.fi-label').text() || 'IMDb',
        value: $element.find('.fi-desc').text().replace(/ , /g, ', '),
      };
    })
    .toArray()
    .slice(0, -1);

  const seasons = $('.seasons.clearfix')
    .find('li')
    .map((_, season) => {
      return {
        name: $(season).text(),
        link: $(season).find('a').attr('href') || link,
      };
    })
    .toArray();

  const movie = {
    title: $moviePage.find('.solototle').text(),
    subTitle: $moviePage.find('.origintitle').text(),
    image: $moviePage.find('.film-poster a').attr('href'),
    quality: $moviePage.find('.film-poster .full-quality').text(),
    description: $moviePage
      .find('.full-text.clearfix')
      .text()
      .replace(/\n/g, '')
      .replace(/\t/g, '')
      .trim(),
    infoSections,
    id: $moviePage.find('.playlists-ajax').attr('data-news_id'),
    url: $moviePage.find('iframe#pre').attr('src'),
    seasons,
  };

  return movie;
};

export const getSources = async (id: string) => {
  const data = await get(
    `/engine/ajax/playlists.php?news_id=${id}&xfield=playlist&time=${Date.now()}`,
    false,
    true,
  );

  const $ = load(data.response as any);
  const sources = $('.playlists-lists')
    .find('li')
    .map((_, source) => {
      return {
        id: $(source).attr('data-id'),
        name: $(source).text(),
      };
    })
    .toArray();
  const videos = $('.playlists-videos')
    .find('li')
    .map((_, source) => {
      return {
        name: $(source).text(),
        id: $(source).attr('data-id'),
        link: $(source).attr('data-file'),
        voice: $(source).attr('data-voice'),
      };
    })
    .toArray();
  return {
    sources,
    videos,
  };
};

export const getVideo = async (link: string) => {
  const data = await get(
    link[0] === '/' ? link.replace('//', 'https://') : link,
    true,
  );

  const url = data.match(/file:"(.*?)"/);
  const poster = data.match(/poster:"(.*?)"/);
  const subtitle = data.match(/subtitle:"(.*?)"/);
  const video = {
    url: url ? url[1] : '',
    poster: poster ? poster[1] : '',
    subtitle: subtitle ? subtitle[1] : '',
  };

  return video;
};

export const saveToHistory = (movie: Movie) => {
  const history = JSON.parse(localStorage.getItem(HISTORY) || '[]');
  let newHistory = [];
  if (history.find((item: Movie) => item.link === movie.link)) {
    newHistory = history.map((item: Movie) =>
      item.link === movie.link
        ? { ...movie, time: new Date().getTime() }
        : item,
    );
  } else {
    newHistory = [{ ...movie, time: new Date().getTime() }, ...history];
  }
  localStorage.setItem(HISTORY, JSON.stringify(newHistory));
};
