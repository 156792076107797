import React, { useEffect, useState } from 'react';
import {
  FocusContext,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import Loader from 'components/Loader/Loader';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getMovie, MovieInfo, saveToHistory, URL } from 'api/ua-kino';
import {
  Actions,
  Content,
  Description,
  Info,
  InfoRow,
  Poster,
  SybTitle,
  Title,
  Wrapper,
} from './styles';
import Button from 'components/Button/Button';
import SourcesSection from 'components/SourcesSection/SourcesSection';

enum Tabs {
  MainInfo = 0,
  Sources = 1,
}

interface MoviePageProps {}

const MoviePage = ({}: MoviePageProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const link = searchParams.get('link');

  if (!link) {
    navigate('/');
    return null;
  }

  const { ref, focusKey } = useFocusable();
  const [isLoading, setIsLoading] = useState(true);
  const [movie, setMovie] = useState<MovieInfo | null>(null);
  const [selectedTab, setSelectedTab] = useState(Tabs.MainInfo);

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (movie) {
      saveToHistory({
        name: movie.title,
        link,
        image: URL + movie.image!.replace(URL, ''),
      });
    }
  }, [movie]);

  const fetchData = async () => {
    const data = await getMovie(link);
    setMovie(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();

    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, [link]);

  return (
    <FocusContext.Provider value={focusKey}>
      <Loader isLoading={isLoading}>
        <Wrapper ref={ref}>
          {movie && (
            <>
              <Title>
                <img src='/images/icons/cartoons.svg' alt='title' />
                {movie.title}
              </Title>
              <SybTitle>{movie.subTitle}</SybTitle>
              <Content>
                <Poster
                  src={movie.image ? URL + movie.image.replace(URL, '') : ''}
                  referrerPolicy='no-referrer'
                />
                <Info>
                  {selectedTab === Tabs.MainInfo && (
                    <>
                      {movie.infoSections.map((section, index) => (
                        <InfoRow key={index}>
                          <div>{section.title}</div>
                          <div>{section.value}</div>
                        </InfoRow>
                      ))}
                      <Description>{movie.description}</Description>
                    </>
                  )}
                  {selectedTab === Tabs.Sources && (
                    <SourcesSection movie={movie} link={link} />
                  )}
                </Info>
              </Content>
              <Actions>
                <Button
                  onEnterPress={() => setSelectedTab(Tabs.MainInfo)}
                  focusOnMount
                >
                  Основна інформація
                </Button>
                <Button onEnterPress={() => setSelectedTab(Tabs.Sources)}>
                  Джерела
                </Button>
              </Actions>
            </>
          )}
        </Wrapper>
      </Loader>
    </FocusContext.Provider>
  );
};

export default MoviePage;
