import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 37px;
`;
