import React, { useCallback, useRef } from 'react';
import {
  FocusableComponentLayout,
  FocusContext,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import {
  ContentRowScrollingContent,
  ContentRowScrollingWrapper,
  ContentRowTitle,
  ContentRowWrapper,
} from './styled';
import Asset from '../Asset/Asset';
import { Section } from 'api/ua-kino';
import { useNavigate } from 'react-router-dom';

interface ContentRowProps {
  section: Section;
  onFocus: (
    layout: FocusableComponentLayout,
    props: object,
    details: FocusDetails,
  ) => void;
}

const ContentRow = ({ section, onFocus }: ContentRowProps) => {
  const navigate = useNavigate();
  const { ref, focusKey } = useFocusable({
    onFocus,
  });

  const scrollingRef = useRef(null);

  const onAssetFocus = useCallback(
    ({ x }: { x: number }) => {
      (scrollingRef?.current as any)?.scrollTo({
        left: x,
        behavior: 'smooth',
      });
    },
    [scrollingRef],
  );

  const openMovie = (link: string) => {
    navigate(`/movie/?link=${link}`);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <ContentRowWrapper ref={ref}>
        <ContentRowTitle>{section.name}</ContentRowTitle>
        <ContentRowScrollingWrapper ref={scrollingRef}>
          <ContentRowScrollingContent>
            {section.movies.map(movie => (
              <Asset
                key={section.name + movie.link}
                focusKey={movie.link}
                keyPrefix={section.name}
                image={movie.image}
                title={movie.name}
                onFocus={onAssetFocus}
                onEnterPress={() => openMovie(movie.link)}
              />
            ))}
          </ContentRowScrollingContent>
        </ContentRowScrollingWrapper>
      </ContentRowWrapper>
    </FocusContext.Provider>
  );
};

export default ContentRow;
