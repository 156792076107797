import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: black;
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #00000000, #0a0911);
`;

export const PlayButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: flex;
  gap: 10px;
`;

export const Duration = styled.div`
  position: absolute;
  top: 50%;
  left: 70px;
  transform: translateY(-50%);
  font-family: 'Chivo Mono', monospace;
  font-size: 20px;
  opacity: 0.9;
`;

export const Title = styled.div`
  position: absolute;
  top: 0;
  left: 70px;
  font-size: 30px;
  font-weight: 700;

  div:last-child {
    font-size: 16px;
    opacity: 0.8;
    margin-top: 4px;
  }
`;

export const SettingsBox = styled.div`
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
`;

export const QualityBox = styled.div`
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  right: 1px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 172px;
  background: linear-gradient(135deg, #6c36b1, #0a0911);
  padding: 5px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;
