import React from 'react';
import { LoaderWrapper, LoaderElement } from './styled';

interface LoaderProps {
  isLoading?: boolean;
  children: React.ReactNode;
}

const Loader = ({ isLoading, children }: LoaderProps) => {
  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <LoaderElement>
            <div></div>
            <div></div>
            <div></div>
          </LoaderElement>
        </LoaderWrapper>
      )}
      {children}
    </>
  );
};

export default Loader;
