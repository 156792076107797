import React, { useEffect } from 'react';
import {
  FocusableComponentLayout,
  FocusDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ButtonBox } from './styled';

export interface AssetProps {
  children: React.ReactNode;
  onEnterPress: () => void;
  focusOnMount?: boolean;
  onFocus?: (
    layout: FocusableComponentLayout,
    props: object,
    details: FocusDetails,
  ) => void;
  selected?: boolean;
}

const Button = ({
  children,
  onEnterPress,
  focusOnMount,
  onFocus,
  selected,
}: AssetProps) => {
  const { ref, focused, focusSelf } = useFocusable({
    onFocus,
    onEnterPress,
  });

  useEffect(() => {
    if (focusOnMount) {
      focusSelf();
    }
  }, []);

  return (
    <ButtonBox ref={ref} focused={focused}>
      {selected && <img src='/images/icons/selected.svg' />}
      {children}
    </ButtonBox>
  );
};

export default Button;
