import styled from 'styled-components';

export interface IconButtonBoxProps {
  focused: boolean;
  circle?: boolean;
}

export const IconButtonBox = styled.div<IconButtonBoxProps>`
  width: 32px;
  height: 32px;
  padding: 15px;

  color: #fff;
  ${({ circle, focused }) =>
    circle &&
    `
    background-color:  ${focused ? '#6c36b1' : 'rgba(255,255,255,0.2)'};
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 64px;
    `}

  svg {
    fill: ${({ focused, circle }) =>
      focused && !circle ? '#6c36b1' : 'white'};
  }
`;
