export const MENU_ITEMS = [
  {
    title: 'Головна',
    icon: 'home',
    path: '/',
  },
  {
    title: 'Історія',
    icon: 'history',
    path: '/history',
  },
  {
    title: 'Пошук',
    icon: 'search',
    path: 'search',
  },
  {
    title: 'Фільми',
    icon: 'movies',
    path: 'movies',
  },
  {
    title: 'Серіали',
    icon: 'series',
    path: 'series',
  },
  {
    title: 'Мультфільми',
    icon: 'cartoons',
    path: 'cartoons',
  },
  {
    title: 'Підбірки',
    icon: 'collections',
    path: 'collections',
  },
  {
    title: 'Топи',
    icon: 'tops',
    path: 'tops',
  },
  {
    title: 'Налаштування',
    icon: 'settings',
    path: 'settings',
  },
];
