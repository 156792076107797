import React, { useEffect, useState } from 'react';
import {
  useFocusable,
  FocusContext,
} from '@noriginmedia/norigin-spatial-navigation';
import { MenuWrapper } from './styled';
import MenuItem from '../MenuItem/MenuItem';
import { MENU_ITEMS } from './constants';

interface MenuProps {
  focusKey: string;
}

const Menu = ({ focusKey: focusKeyParam }: MenuProps) => {
  const [selectedItem, setSelectedItem] = useState(MENU_ITEMS[0].path);
  const { ref, focusSelf, hasFocusedChild, focusKey } = useFocusable({
    focusable: true,
    saveLastFocusedChild: false,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: false,
    focusKey: focusKeyParam,
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: () => true,
    onFocus: () => {},
    onBlur: () => {},
    extraProps: { foo: 'bar' },
  });

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <FocusContext.Provider value={focusKey}>
      <MenuWrapper ref={ref} hasFocusedChild={hasFocusedChild}>
        {MENU_ITEMS.map(item => (
          <MenuItem
            {...item}
            selectedItem={selectedItem}
            key={item.title}
            changeSelected={setSelectedItem}
          />
        ))}
      </MenuWrapper>
    </FocusContext.Provider>
  );
};

export default Menu;
