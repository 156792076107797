import React, { useCallback } from 'react';
import {
  FocusContext,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ScrollingRows } from './styled';
import ContentRow from '../../components/ContentRow/ContentRow';
import Loader from 'components/Loader/Loader';
import { Section } from 'api/ua-kino';
import { ContentWrapper } from 'styled';
import { useNavigate } from 'react-router-dom';

interface HomePageProps {
  isLoading: boolean;
  sections: Section[];
}

const HomePage = ({ isLoading, sections }: HomePageProps) => {
  const { ref, focusKey } = useFocusable();

  const onRowFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <Loader isLoading={isLoading}>
        <ContentWrapper>
          <ScrollingRows ref={ref}>
            <div>
              {sections.map(section => (
                <ContentRow
                  key={section.name}
                  section={section}
                  onFocus={onRowFocus}
                />
              ))}
            </div>
          </ScrollingRows>
        </ContentWrapper>
      </Loader>
    </FocusContext.Provider>
  );
};

export default HomePage;
