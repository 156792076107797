import styled from 'styled-components';

export const SubTitle = styled.div`
  min-width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 20px;
`;

export const DebagOutput = styled.div`
  min-width: 100%;
  white-space: pre;
  font: 14px/1.5 monospace;
`;

export const QualityBox = styled.div`
  width: 600px;
  display: flex;
  gap: 10px;
  margin-top: 5px;
`;
