import styled from 'styled-components';

export interface ButtonBoxProps {
  focused: boolean;
}

export const ButtonBox = styled.div<ButtonBoxProps>`
  padding: 12px 32px;
  object-fit: cover;
  border-color: 'white';
  background: ${({ focused }) => (focused ? '#ffffff33' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 22px;
  }
`;
