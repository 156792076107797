import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 37px;
`;

export const Title = styled.div`
  font-size: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
`;

export const SybTitle = styled.div`
  font-size: 16px;
  margin-left: 60px;
  opacity: 0.6;
`;

export const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 37px;
  height: 460px;
  display: flex;
`;

export const Actions = styled.div`
  height: 46px;
  display: flex;

  div {
    margin-right: 16px;
  }
`;

export const Poster = styled.img`
  min-width: 320px;
  width: 320px;
  height: 460px;
  object-fit: cover;
  box-shadow: 0px 0px 15px -5px #00000080;
`;

export const Info = styled.div`
  font-size: 18px;
  margin-left: 37px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const InfoRow = styled.div`
  display: flex;
  margin-bottom: 9px;

  div:first-child {
    margin-right: 8px;
    opacity: 0.8;
  }
`;

export const Description = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
`;
