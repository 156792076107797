import styled from 'styled-components';

export const ContentRowWrapper = styled.div`
  margin-bottom: 14px;
`;

export const ContentRowTitle = styled.div`
  color: white;
  margin-bottom: 12px;
  font-size: 27px;
  font-weight: 700;
  padding-left: 60px;
`;

export const ContentRowScrollingWrapper = styled.div`
  overflow-x: visible;
  overflow-y: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 60px;
  padding-right: 60px;
  mask-image: linear-gradient(270deg, black 93%, transparent);
  -webkit-mask-image: linear-gradient(270deg, black 93%, transparent);
`;

export const ContentRowScrollingContent = styled.div`
  display: flex;
  flex-direction: row;

  &::after {
    content: ',';
    visibility: hidden;
    display: block;
    width: 37px;
    height: 10px;
    margin-left: 0px;
  }
`;
